import React, { useState, useCallback, useRef, useEffect } from "react";
import theme, {
  StyledToggleButtonGroup,
  StyledOutputButton,
  IOSSlider,
  boxShadow,
} from "./programmerTheme.js";
import { ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import IconButton from "@mui/material/IconButton";
import { Button, TouchRipple } from "@mui/material";
import Input from "@mui/material/Input";
import Tooltip from "@mui/material/Tooltip";

const DisplayActivation = (props) => {
  const config = props.config;
  const outputs = props.outputs;
  const [activation, setActivation] = useState(props.activation);

  useEffect(() => {
    setActivation(props.activation);
  }, [props.activation]);

  const [activationDescription, setActivationDescription] = React.useState(
    activation.description
  );
  const [defaultValue, setDefaultValue] = React.useState([0, 1440]);
  const [_min, setMin] = React.useState(0);
  const [_max, setMax] = React.useState(1440);
  const [step, setStep] = React.useState(1);

  const getMinutesFromDateIsoString = (dateIsoString, offset) => {
    var date = new Date(dateIsoString);
    if (offset) {
      date = new Date(date.getTime() + offset);
    }
    return date.getHours() * 60 + date.getMinutes();
  };

  const [value, setValue] = React.useState([
    getMinutesFromDateIsoString(
      props.program.startTimestamp,
      activation.offset
    ),
    getMinutesFromDateIsoString(
      props.program.startTimestamp,
      activation.offset + activation.unitsLimit
    ),
  ]);

  const [_marks, setMarks] = React.useState([
    { value: 0, label: "00:00" },
    {
      value: 720,
      label: "12:00",
    },
    { value: 1440, label: "24:00" },
  ]);

  const onChangeDescription = (value) => {
    //ojo setActivationDescription(value);
    //activation.description = value;
    props.onChangeDescription(value);
  };

  const [deleteActivation, setDeleteActivation] = useState(false);

  const onDeleteActivation = () => {
    if (!deleteActivation) {
      setDeleteActivation(true);

      setTimeout(() => {
        setDeleteActivation(false);
      }, 5000);
    } else {
      setDeleteActivation(false);
      props.onDeleteActivation(activation);
    }
  };

  
  const onChangeTime = (event, value) => {
  
    props.onChangeActivationTimes(value);
  };

  const onChange = (event, newValue) => {
   
    if (config) {
      setCurrentDragging(value[0] === newValue[0] ? 1 : 0);
      setValue(newValue);
    }
  };

  const [currentDragging, setCurrentDragging] = useState(-1);

  const [dragging, setDragging] = useState(false);

  const handleDragStart = () => {
    setDragging(true);
   
    setTimeout(() => {
      if (!dragging)
      setStep(step === 5 ? 1 : 5);
  }, 2000);
   
  };

  const handleDragEnd = () => {
    setDragging(false);
    
    
    //   valueLabelFormat(value, currentDragging);
    // setCurrentDragging(-1);
  };

  const handleBlur = (event) => {
    clearTimeout()
    //setDragging(false);
    // valueLabelFormat(value, currentDragging);
    //setCurrentDragging(-1);
  };



  const valueLabelFormat = (value, index) => {
    const hour = toTime(value);
    return dragging && currentDragging === index
      ? ValueLabelActive(hour, index)
      : ValueLabel(hour, index);
  };

  const ValueLabelActive = (hour, index) => {
    return (
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            position: "absolute",
            justifyItems: "center",
            justifyContent: "center",
            padding: 0,
            paddingRight: 0.05,
            top: -42,
            left: "-50%",
            backgroundColor: "transparent",
            // opacity:0.5,
            borderRadius: 10,
           
          }}
        >
          <Typography
            sx={{
              backgroundColor: "transparent", // "#2A2F35",
              color: index === 0 ? "#5CB973" : "#ED675C",
              textAlign: "center",
              justifyItems: "center",
              justifyContent: "center",
              padding: 0,
              fontSize: window.innerWidth > 400 ? "32px" : "24px",
            }}
            variant="h5"
          >
            {hour}
          </Typography>
        </Box>
      </ThemeProvider>
    );
  };

  const ValueLabel = (hour, index) => {
    return (
      <div
        component="Box"
        style={{
          backgroundColor: "transparent",
          color: index === 0 ? "#5CB973" : "#ED675C",
          paddingRight: index === 0 ? (window.innerWidth > 400 ? 64 : 38) : 0,
          paddingLeft: index === 0 ? 0 : (window.innerWidth > 400 ? 64 : 38),
          top: 0,
          fontSize: window.innerWidth > 400 ? "20px" : "14px"
        }}
      >
        {hour}
      </div>
    );
  };

  function getOutputsFromBits(outputNum) {
    var parsed = [];
    outputs.map((output, index) => {
      if (outputNum & (1 << index)) parsed.push(output.output);
    });
    return parsed;
  }

  const handleChange = (event, newOutputs) => {
    const newOutputsNumber = getOutputsOn(newOutputs);
    activation.outputs = newOutputsNumber;
    props.onChangeActivationOutputs(newOutputsNumber);
  };

  // devuelve el entero de los días seleccionados
  function getOutputsOn(newOutputs) {
    var zeros = Array(outputs.length).fill(0);
    newOutputs.map((output) => {
      zeros[outputs.length - output] = 1;
    });
    return parseInt(zeros.join(""), 2);
  }

  return (
    <ThemeProvider theme={theme}>
      <Table
        sx={{
          padding: 0,
          borderTop: "1px solid #2A160B",
        }}
      >
        <TableRow
          sx={{
            padding: 0,
          }}
        >
          <TableCell colSpan={3} sx={{ border: 0, padding: 0 }}>
            <Box
              display={"flex"}
              sx={{
                padding: 0,
                fontSize: 10,
                border: 0,
                justifyItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <IconButton
                disabled={!config}
                onClick={onDeleteActivation}
                center={true}
                value="deleteProgram"
                aria-label="deleteProgram"
                sx={{ color: "#333333" }}
              >
                <DeleteForeverOutlinedIcon
                  sx={{ color: deleteActivation ? "#DD362E" : "#524D32" }}
                />
              </IconButton>

              <Avatar
                sx={{
                  backgroundColor: "transparent",
                  width: 24,
                  height: 24,
                  border: "1px solid #524D32",
                  color: "#524D32",
                  fontSize: 12,
                  margin: 1,
                }}
              >
                {props.index + 1}
              </Avatar>

              <Input
                id="standard-basic"
                //disableUnderline={true}
                //  multiline
                sx={{ fontSize: window.innerWidth > 400 ? "20px" : "14px"}}
                value={props.activation.description}
                onChange={(e) => onChangeDescription(e.currentTarget.value)}
              />
            </Box>
          </TableCell>
        </TableRow>
        <TableRow
          sx={{
            padding: 0,
          }}
        >
          <TableCell colSpan={3} sx={{ border: 0, padding: window.innerWidth > 400 ? 4 : 0 }}>
            <Box
              display={"flex"}
              sx={{
                padding: 0,
              }}
            >
              <IOSSlider
                sx={{
                  marginLeft: 5,
                  marginTop: 6,
                  marginBottom: 2,
                  marginRight: 5,
                  color: step === 1 ? "#914B29" : "#ED675C",
                  "& .MuiSlider-thumb": {
                    width: step === 1 ? 24 : 32,
                    height: step === 1 ? 24 : 32,
                  },
                }}
                draggable={true}
                onTouchStart={handleDragStart}
                onTouchEnd={handleDragEnd}
            //    onMouseDown={handleOnMouseDown}
              //  onMouseUp={handleDragEnd}
             //   onBlur={handleBlur}
                onChangeCommitted={onChangeTime}
                component="slider"
                disableSwap={true}
                step={step}
                // size={steps === 1 ? "64px" : "4"}
                
                defaultValue={value}
                min={_min}
                disabled={!config}
                max={_max}
                value={value}
                onChange={(event, value) => onChange(event, value)}
                valueLabelDisplay="on"
                marks={_marks}
                //  valueMarksFormat={valueLabelFormat}
                //   getAriaValueText={(value) => valuetext}
                valueLabelFormat={valueLabelFormat}
              />
            </Box>
            {/**
          <TextField
                        id="time"
                        label="Hora"
                        type="time"
                        defaultValue="00:00:00"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 1,
                        }}
                      /> */}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            sx={{ backgroundColor: "transparent", padding: 1 }}
            colSpan={3}
          >
            <Box
              component={"div"}
              sx={{ backgroundColor: "transparent", display: "grid", paddingTop:1, }}
            >
              <Typography variant={"subtitle2"} sx={{fontSize: window.innerWidth > 400 ? "16px" : "12px",}}>Salidas</Typography>
              <StyledToggleButtonGroup
                variant="contained"
                spacin={1}
                component={"div"}
                size="small"
                value={getOutputsFromBits(props.activation.outputs)}
                disabled={!props.config}
                //   blurOnSelect={true}
                sx={{ backgroundColor: "transparent", padding: 0 }}
                onChange={handleChange}
              >
                {outputs.map((d) => (
                  <StyledOutputButton
                    disabled={!config}
                    value={d.output}
                    aria-label={d.output}
                    sx={{alignContent:'center', fontSize: window.innerWidth > 400 ? 16 : 12,}}
                    //    onClick={handleToggle(index)}
                  >
                    {d.output}
                  </StyledOutputButton>
                ))}
              </StyledToggleButtonGroup>
            </Box>
          </TableCell>
        </TableRow>
      </Table>
    </ThemeProvider>
  );
};

const toTime = (mins) => {
  var hours = Math.floor(mins / 60);
  var minutes = mins % 60;

  return (
    hours.toString().padStart(2, "0") +
    ":" +
    minutes.toString().padStart(2, "0")
  );
};

export default DisplayActivation;
