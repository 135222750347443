import React, { useRef, useState, useCallback, useEffect } from "react";
import theme, { boxShadow } from "./programmerTheme.js";
import { ThemeProvider } from "@mui/material/styles";
import { v4 as uuidv4 } from "uuid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

import DisplayProgram from "./displayProgram";
import TableRow from "@mui/material/TableRow";
import { TableCell } from "@mui/material";

const Programmer = (props) => {
  const {
    terminalDevice,
    triggers,
    onCreateTrigger,
    onUpdateTrigger,
    onDeleteTrigger,
  } = props;
  const { terminalId, id: terminalDeviceId } = terminalDevice;
  const terminalName = terminalDevice ? terminalDevice.device.name : "";
  const outputs = terminalDevice.outputs.sort((a, b) =>
    a.output > b.output ? 1 : -1
  );
  const [config, setConfig] = React.useState(false);

  const loadInitialData = () => {

    var programs = [];

    if (triggers !== undefined) {
      triggers
        .filter((program) => program.deletedByUser === false)
        .forEach((p, i) => {
            programs.push(p);
        });
    }

    return JSON.parse(JSON.stringify(programs));
  };

  const [currentPrograms, setCurrentPrograms] = React.useState(() =>
    loadInitialData()
  );

  useEffect(() => {

    setCurrentPrograms(JSON.parse(JSON.stringify(loadInitialData())));
  }, [props.triggers]);

  const ref = useRef(null);
  const handleScroll = () => {
    setTimeout(() => {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  const confirmChangeProgram = (program) => {
    const newProgram = program.id.toString().startsWith("APP-");
    const programs = JSON.parse(JSON.stringify(currentPrograms));

      //props.onUpdateTrigger(terminalId, terminalDeviceId, program.id, program);
    if (newProgram) {
     
      const token = program.id;
      onCreateTrigger(terminalDevice, program).then(
        (program) => {
          const programIndex = programs.findIndex(
            (cacheTrigger) => cacheTrigger.id === token
          );
          program.active = true
          program.success=true
          if (programIndex >= 0) {
             programs[programIndex] = program;
          } else {
            //   programs.push(program);
          }
          setCurrentPrograms(JSON.parse(JSON.stringify(programs)));
        },
        (error) => alert("ERROR")
      );
    } else {
      onUpdateTrigger(terminalDevice, program).then(
        (program) => {
          const programIndex = currentPrograms.findIndex(
            (cacheTrigger) => cacheTrigger.id === program.id
          );

          if (programIndex >= 0) {
            currentPrograms[programIndex] = program;
          } else {
            currentPrograms.push(program);
          }
          program.success=true
          updateProgram(program);
         
          setCurrentPrograms(JSON.parse(JSON.stringify(currentPrograms)));
        },
        (error) => alert("ERROR")
      );
    }
  };

  const createProgram = () => {
    const token = "APP-" + uuidv4();

    var program = {
      id: token,
      terminalDevice: terminalDeviceId,
      type: "PROGRAM",
      operator: "WEEKLY",
      active: true,
      notified: false,
      activations: [
        {
          id: 1,
          triggerId: 0,
          terminalDeviceOutputs: terminalDeviceId,
          outputs: 0,
          prevId: 0,
          pwm: 100,
          unitControl: "TIME",
          cudId: 0,
          unitsLimit: 86400000,
          description: "Primera activación",
          offset: 0,
        },
      ],
      startTimestamp: new Date().toISOString(),
      endTimestamp: new Date().toISOString(),
      loop: false,
      days: 127,
      description: "Nuevo programa " + (currentPrograms.length + 1),
    };

    setCurrentPrograms([...currentPrograms, program]);
    setConfig(true);
    handleScroll();
  };



  const deleteProgram = (program) => {
    // MARCALO COM ELIMINANT.

    if (program.id > 0) {
        program.deletedByUser = true
      // ya existía en el servidor
      //props.onDeleteTrigger(terminalId, terminalDeviceId, program.id, program);
      onDeleteTrigger(terminalDevice, program).then(
        (program) => {
          const programIndex = currentPrograms.findIndex(
            (cacheTrigger) => cacheTrigger.id === program.id
          );

          if (programIndex >= 0) {
            currentPrograms.splice(programIndex, 1);
            setCurrentPrograms(JSON.parse(JSON.stringify(currentPrograms)));
          }
        },
        (error) => alert("ERROR")
      );
    }
  };


  const updateProgram = (program) => {
    var programs = [];

    currentPrograms.forEach((p, i) => {
      if (p.id === program.id) {
        programs.push(program);
      } else {
        programs.push(p);
      }
    });
    setCurrentPrograms(JSON.parse(JSON.stringify(programs)));
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        component="TableRow"
        sx={{
          fullWidth: true,
          overflow: "hidden",
          backgroundColor: "transparent",
          padding: 0,
        }}
      >
        <TableRow
          sx={{
            border: 0,
            boxShadow: boxShadow,
            backgroundColor: "#F3E8E4",
          }}
        >
          <TableCell sx={{}}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => window.location.reload(true)}
            >
              <ArrowBackRoundedIcon />
            </IconButton>
          </TableCell>
          <TableCell sx={{ width: "100%", justifyItems: "center" }}>
            <Box sx={{ display: "flow", justifyItems: "center", maxWidth:"300px" }}>
              <Typography
                aria-multiline={true}
                variant="subtitle1"
                component="div"
                sx={{fontSize: window.innerWidth > 400 ? "20px" : "14px",}}
              >
                {terminalName}
              </Typography>
              <Typography
                aria-multiline={true}
                variant="subtitle2"
                component="div"
                sx={{fontSize: window.innerWidth > 400 ? "16px" : "12px",}}
              >
                {"Terminal de desarrollo"}
              </Typography>
            </Box>
          </TableCell>
          <TableCell sx={{}}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              // eslint-disable-next-line no-unused-expressions
              onClick={createProgram}
            >
              <AddIcon />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            colspan={3}
            sx={{
              padding: 0.5,
              paddingTop: 1,
              justifyItems: "center",
              border: 0,
            }}
          >
            <Box sx={{ display: "flow" }}>
              {currentPrograms.map((program, index) => (
                <DisplayProgram
                  config={config}
                  program={program}
                  outputs={outputs}
                  confirmChangeProgram={confirmChangeProgram}
                  updateProgram={updateProgram}
                  deleteProgram={deleteProgram}
                />
              ))}
            </Box>
          </TableCell>
        </TableRow>
      </Box>
      <div ref={ref} />
    </ThemeProvider>
  );
};

export default Programmer;
