import React, { useState, useEffect, useCallback } from "react";
import Programmer from "./programmer";

import { data as triggers } from "./programs.json";
import selectedTerminalDevice from "./terminalDevice.json";




function App() {
  function later(delay) {
    return new Promise(function(resolve) {
        setTimeout(resolve, delay);
    });
  }
  
  const createTrigger = useCallback((terminalDevice, triggerDto) => {
    return later(500).then(() => ({...triggerDto, id: Math.floor(Math.random() * 10000), notified: false}))
    /*triggersService
      .save(terminalDevice?.terminal, terminalDevice?.id, triggerDto)*/
  },[])// [triggersService])
  const updateTrigger = useCallback((terminalDevice, triggerDto) => {
    return later(500).then(() => ({...triggerDto, notified: false}))
    /*return triggersService
      .update(terminalDevice?.terminal, terminalDevice?.id, triggerDto.id, triggerDto)*/
    },[])// [triggersService])
  const deleteTrigger = useCallback((terminalDevice, triggerDto) => {
    return later(500).then(() => ({...triggerDto, deletedByUser: true}))
    /*triggersService
          .deleteTrigger(
            terminalDevice?.terminal,
            terminalDevice?.id,
            triggerDto.id
          )*/
        },[])// [triggersService])
  
  return (
    <Programmer 
                terminalDevice={selectedTerminalDevice}
                triggers={triggers}
                onCreateTrigger = {createTrigger}
                onUpdateTrigger = {updateTrigger}
                onDeleteTrigger = {deleteTrigger} />
  );
}

export default App;
