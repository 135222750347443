import React, { useState, useEffect, useRef } from "react";
import theme, { boxShadow } from "./programmerTheme.js";
import { ThemeProvider } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import AccessAlarmsRoundedIcon from "@mui/icons-material/AccessAlarmsRounded";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import UpdateRoundedIcon from "@mui/icons-material/UpdateRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Avatar from "@mui/material/Avatar";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { AddOutlined } from "@mui/icons-material";
import Input from "@mui/material/Input";
import DisplayDays from "./displayDays";
import DisplayActivation from "./displayActivation";

import Switch from "@mui/material/Switch";
import _ from "lodash";

const label = { inputProps: { "aria-label": "Size switch demo" } };

const DisplayProgram = (props) => {
  const outputs = props.outputs;
  const program = props.program;
  const [waitingId, setWaitingId] = useState(
    (props.program.id + "").startsWith("APP-")
  );

  const [config, setConfig] = useState(props.config);
  const [initialProgram, setInitialProgram] = React.useState(
    JSON.parse(JSON.stringify(props.program))
  );

  const programChanged = () => {

    if (program.id.toString().startsWith("APP-")) return true;

    if (_.isEqual(initialProgram, program)) return false;
    
    return true;
  };

  const [currentProgram, setCurrentProgram] = React.useState(program);

  useEffect(() => {
    // alert(program.id)
    setWaitingId((program.id + "").startsWith("APP-"));

    if (program.success) {
      delete program.success;
      setInitialProgram(program);
      setConfig(false);
      setInitialProgram(program);
      setConfig(false);
    }

    setCurrentProgram(JSON.parse(JSON.stringify(program)));
  }, [program]);

  const ref = useRef(null);

  const handleScroll = () => {
    setTimeout(() => {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  const [activationDetail, setActivationDetail] = useState(false);

  const getTimeFromDateIsoString = (dateIsoString, offset) => {
    const options = { hour12: false, hour: "2-digit", minute: "2-digit" };
    var date = new Date(dateIsoString);
    if (offset) {
      date = new Date(date.getTime() + offset);
    }
    return date.toLocaleTimeString("es-ES", options);
  };

  function isActivationStarted(offset, unitsLimit) {
    const start = getMillisecons(currentProgram.startTimestamp) + offset;
    const end = start + unitsLimit;
    const now = getMillisecons();
    var dayOfWeek = currentProgram.days & (1 << new Date().getDay());

    return dayOfWeek && now > start && now < end ? 1 : 0;
  }

  const anyOutputOpened = () => {
    var count = 0;
    currentProgram.activations.forEach((a) => {
      if (isActivationStarted(a.offset, a.unitsLimit)) {
        count++;
      }
    });
    return count;
  };

  function getMillisecons(dateString) {
    const date = dateString ? new Date(dateString) : new Date();
    var millisecons = date.getMilliseconds();
    millisecons += date.getSeconds() * 1000;
    millisecons += date.getMinutes() * 60000;
    millisecons += date.getHours() * 3600000;
    return millisecons;
  }

  const [activationDescription, setActivationDescription] =
    React.useState("Nueva activación");
  const [activationOutputs, setActivationOutputs] = useState(0);
  const [currentActivation, setCurrentActivation] = useState();

  const changeActiveProgram = (event) => {
    currentProgram.active = !currentProgram.active;
    props.updateProgram(currentProgram);
  };

  const changeProgramDescription = (value) => {
    currentProgram.description = value;
    props.updateProgram(currentProgram);
  };

  const changeProgramDays = (value) => {
    currentProgram.days = value;
    setCurrentProgram(JSON.parse(JSON.stringify(currentProgram)));
    props.updateProgram(currentProgram);
  };

  const changeActivationDescription = (value, activation, program) => {
    program.activations.forEach((a) => {
      if (a.id === activation.id) {
        activation.description = value;
      }
    });

    setCurrentProgram(JSON.parse(JSON.stringify(program)));
    props.updateProgram(program);
  };

  const changeActivationOutputs = (value, activation, program) => {
    program.activations.forEach((a) => {
      if (a.id === activation.id) {
        activation.outputs = value;
      }
    });

    setCurrentProgram(JSON.parse(JSON.stringify(program)));
    props.updateProgram(program);
  };

  const getMilliseconsFromDateIsoString = (dateIsoString) => {
    var date = new Date(dateIsoString);

    return (date.getHours() * 60 + date.getMinutes()) * 60000;
  };

  const getMinutesFromDateIsoString = (dateIsoString, offset) => {
    var date = new Date(dateIsoString);
    if (offset) {
      date = new Date(date.getTime() + offset);
    }
    return date.getHours() * 60 + date.getMinutes();
  };

  const changeActivationTimes = (value, activation, program) => {
    const sliderData = [];
    program.activations.forEach((a) => {
      if (a.id === activation.id) {
        sliderData.push(value);
      } else {
        sliderData.push([
          getMinutesFromDateIsoString(program.startTimestamp, a.offset),
          getMinutesFromDateIsoString(
            program.startTimestamp,
            a.offset + a.unitsLimit
          ),
        ]);
      }
    });

    const minutesStart = sliderData.reduce((acc, curr) => {
      return acc[0] < curr[0] ? acc : curr;
    })[0];

    const minutesEnd = sliderData.reduce((acc, curr) => {
      return acc[1] > curr[1] ? acc : curr;
    })[1];

    const date = new Date();
    date.setHours(minutesStart / 60);
    date.setMinutes(minutesStart % 60);
    date.setSeconds(0);
    program.startTimestamp = date.toISOString();

    date.setHours(minutesEnd / 60);
    date.setMinutes(minutesEnd % 60);
    date.setSeconds(0);
    program.endTimestamp = date.toISOString();

    program.activations.forEach((a, i) => {
      a.offset = (sliderData[i][0] - minutesStart) * 60000;
      a.unitsLimit = (sliderData[i][1] - sliderData[i][0]) * 60000;
    });

    setCurrentProgram(JSON.parse(JSON.stringify(program)));
    props.updateProgram(program);
  };

  const [daysSelected, setDaysSelected] = useState(
    getDaysFromBits(currentProgram.days)
  );

  function getDaysFromBits(days) {
    const letters = ["D", "L", "M", "X", "J", "V", "S"];
    var parsed = [];
    letters.forEach((letter, index) => {
      if (days & (1 << (6 - index))) parsed.push(letter);
    });

    return parsed;
  }

  const programAction = (program, action) => {
    switch (action) {
      case "confirmChangeProgram":
        props.confirmChangeProgram(currentProgram);
        break;
      case "addActivation":
        createActivation(program);
        // setOpenAdd(true);
        break;
      case "deleteProgram":
        props.deleteProgram(currentProgram);
        break;
      default:
        alert(program + " " + action);
        break;
    }
  };

  const onDeleteActivation = (activation, program) => {
    var activations = [];

    program.activations.forEach((p, i) => {
      if (p.id !== activation.id) {
        activations.push(p);
      }
    });
    program.activations = JSON.parse(JSON.stringify(activations));
    setCurrentProgram(JSON.parse(JSON.stringify(currentProgram)));
    props.updateProgram(currentProgram);
  };

  const changeConfig = () => {
    if (!waitingId) setConfig(!config);
  };

  const createActivation = () => {
    const activation = {
      id: currentProgram.activations.length + 1,
      triggerId: 0,
      terminalDeviceOutputs: currentProgram.terminalDevice,
      outputs: 0,
      prevId: 0,
      pwm: 100,
      unitControl: "TIME",
      cudId: 0,
      unitsLimit: getMillisecons(currentProgram.endTimestamp),
      description: "Nueva activación",
      offset: getMillisecons(currentProgram.startTimestamp),
    };
    setCurrentActivation(activation);
    setActivationDescription("Nueva activación");

    currentProgram.activations.push(activation);
    setCurrentProgram(JSON.parse(JSON.stringify(currentProgram)));
    props.updateProgram(currentProgram);
    handleScroll();
  };

  const editActivation = (activation, program) => {
    setActivationOutputs(activation.outputs);

    setCurrentActivation(activation);
  };

  const updateActivation = () => {
    currentActivation.description = activationDescription
      ? activationDescription
      : "Nueva activación";
    currentActivation.outputs = activationOutputs;

    const activations = [];
    currentProgram.activations.map((a) => {
      if (a.id === currentActivation.id) {
        activations.push(currentActivation);
      } else {
        activations.push(a);
      }
    });

    currentProgram.activations = activations;

    setCurrentProgram(JSON.parse(JSON.stringify(currentProgram)));
    props.updateProgram(currentProgram);
  };

  const saveActivation = (newActivation) => {
    if (newActivation) {
      createActivation();
    } else {
      updateActivation();
    }
  };

  const [deleteProgram, setDeleteProgram] = useState(false);

  const onDeleteProgram = () => {
    if (!deleteProgram) {
      setDeleteProgram(true);

      setTimeout(() => {
        setDeleteProgram(false);
      }, 5000);
    } else {
      setConfig(false);
      setDeleteProgram(false);
      setInitialProgram(null);
      props.deleteProgram(currentProgram);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        disabled={true}
        sx={{
          display: "flow",
          marginBottom: 2,
          padding: 0,
          border: "1px solid #2A160B",
          backgroundColor: config
            ? "#F3E8E4"
            : programChanged()
            ? "#F3E8E4"
            : "transparent",
        }}
      >
        <Table sx={{ padding: 0 }}>
          <TableRow
            sx={{
              border: 0,
              borderBottom: "0px solid #333333",
              boxShadow: boxShadow,
            }}
          >
            <TableCell
              sx={{
                padding: 2,
                paddingLeft: 2,
                paddingRight: 2,
                width: "100%",
              }}
              align="left"
            >
              {!currentProgram.notified && currentProgram.id > 0 ? (
                <Box sx={{ position: "relative", top: -4, right: -56 }}>
                  <CircularProgress
                    color="inherit"
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 4,
                    }}
                  />
                </Box>
              ) : (
                <div />
              )}
              {config && !props.closeConfig ? (
                <Input
                  id="standard-basic"
                  //  disableUnderline={true}
                  autofocus
                  fullWidth
                  multiline
                  sx={{
                    variant: "subtitle1",
                    fontSize: window.innerWidth > 400 ? "20px" : "14px",
                  }}
                  //
                  helperText="Introduzca el nombre del programa"
                  value={currentProgram.description}
                  label="Standard"
                  onChange={(e) =>
                    changeProgramDescription(e.currentTarget.value)
                  }
                />
              ) : (
                <Typography
                  fullWidth
                  wrap
                  alignContent={"middle"}
                  variant="subtitle1"
                  sx={{ fontSize: window.innerWidth > 400 ? "20px" : "14px" }}
                >
                  {currentProgram.description}
                </Typography>
              )}
            </TableCell>

            <TableCell
              sx={{
                backgroundColor: "transparent",
                padding: 0,
                paddingRight: 1,
                border: "0",
              }}
            >
              <IconButton
                onClick={changeConfig}
                value="config"
                sx={{
                  //   backgroundColor: "#2A2F35",
                  padding: 0.5,
                  paddingRight: 2,
                  border: "0",
                  color: waitingId ? "#A7A7A7" : "#333333",
                }}
              >
                {config && !props.closeConfig ? (
                  <ArrowBackRoundedIcon />
                ) : (
                  <TuneRoundedIcon
                    sx={{ color: programChanged() ? "#DD362E" : "#333333" }}
                  />
                )}
              </IconButton>
            </TableCell>
          </TableRow>

          {config && !props.closeConfig ? (
            <TableRow
              sx={{
                padding: 0,
                border: 0,
              }}
            >
              <TableCell
                colSpan="2"
                sx={{
                  flexGrow: 1,
                  padding: 0,
                  paddingLeft: 1,
                  border: 0,
                  borderBottom: "1px solid #A7A7A7",
                  backgroundColor: "#F3E8E4",
                  borderTop: "2px solid #FFFFFF",
                  boxShadow: boxShadow,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <BottomNavigation
                    sx={{ backgroundColor: "transparent" }}
                    showLabels
                    size="small"
                    //   value={value}

                    onChange={(event, newValue) => {
                      //   setValue(newValue);
                    }}
                  >
                    <BottomNavigationAction
                      onClick={onDeleteProgram}
                      sx={{
                        flexGrow: 1,
                        color: deleteProgram ? "#DD362E" : "#524D32",
                      }}
                      label={"Eliminar"}
                      icon={
                        <DeleteForeverRoundedIcon
                          sx={{ color: deleteProgram ? "#DD362E" : "#524D32" }}
                        />
                      }
                    />

                    <BottomNavigationAction
                      showLabel
                      //   onClick={changeActiveProgram}
                      disabled={currentProgram.id < 0}
                      sx={{
                        flexGrow: 1,
                        color: currentProgram.id < 0 ? "#A7A7A7" : "#333333",
                      }}
                      value="config"
                      label={currentProgram.active ? "Activo" : "Detenido"}
                      icon={
                        <Switch
                          onChange={changeActiveProgram}
                          {...label}
                          checked={currentProgram.active}
                          size="small"
                        />
                      }
                    />
                    <BottomNavigationAction
                      onClick={() => props.confirmChangeProgram(currentProgram)}
                      sx={{
                        //// && currentProgram.notified) || waitingId
                        flexGrow: 1,
                        color: programChanged() ? "#DD362E" : "#A7A7A7",
                      }}
                      label="Aplicar"
                      icon={<UpdateRoundedIcon />}
                    />
                  </BottomNavigation>
                </Box>
              </TableCell>
            </TableRow>
          ) : (
            <span />
          )}
          <TableRow sx={{ padding: 0 }}>
            <TableCell
              colSpan="2"
              sx={{
                justifyItems: "center",
                padding: 0.5,
                paddingTop: config ? 0 : 1,
                display: "flow",
              }}
            >
              <DisplayDays
                program={currentProgram}
                daysSelected={daysSelected}
                config={config && !props.closeConfig}
                changeProgramDays={changeProgramDays}
              />

              <Collapse
                sx={{
                  border: 0,
                  padding: 0,
                  width: "100%",
                }}
                in={config && !props.closeConfig}
                timeout="auto"
                unmountOnExit
              >
                <Box fullWidth sx={{ display: "flow", padding: 0 }}>
                  <TableRow
                    sx={{
                      border: 0,
                      padding: 0,
                      justifyItems: "flex-start",
                      //paddingLeft: 2,
                    }}
                  >
                    <TableCell
                      sx={{
                        border: 0,
                        padding: 0,
                        justifyItems: "flex-start",
                        paddingTop: 1,
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{
                          border: 0,
                          padding: 0,
                          paddingLeft: 1,
                          paddingRight: 1,
                          justifyItems: "flex-start",
                          fontSize: window.innerWidth > 400 ? "16px" : "12px",
                        }}
                      >
                        Activaciones del programa
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: 0,
                        paddingRight: 1.6,
                        backgroundColor: "transparent",
                        border: 0,
                      }}
                    >
                      {config && !props.closeConfig ? (
                        <IconButton
                          value="addActivation"
                          onClick={() =>
                            programAction(currentProgram, "addActivation")
                          }
                          sx={{
                            border: 0,
                            color: "#333333 ",
                          }}
                        >
                          <AddOutlined />
                        </IconButton>
                      ) : (
                        <div />
                      )}
                    </TableCell>
                  </TableRow>
                </Box>
                <Box
                  component="div"
                  sx={{
                    fullWidth: true,
                    padding: 0,
                    display: "flow",
                  }}
                >
                  {
                    // const milliseconds = date.getTime();

                    currentProgram.activations.map((activation, index) => (
                      <DisplayActivation
                        config={config}
                        daysSelected={daysSelected}
                        program={currentProgram}
                        outputs={outputs}
                        index={index}
                        activation={activation}
                        onDeleteActivation={() =>
                          onDeleteActivation(activation, currentProgram)
                        }
                        onEditActivation={() =>
                          editActivation(activation, currentProgram)
                        }
                        onSaveActivation={saveActivation}
                        onChangeDescription={(value) =>
                          changeActivationDescription(
                            value,
                            activation,
                            currentProgram
                          )
                        }
                        onChangeActivationOutputs={(value) =>
                          changeActivationOutputs(
                            value,
                            activation,
                            currentProgram
                          )
                        }
                        onChangeActivationTimes={(value) => {
                          changeActivationTimes(
                            value,
                            activation,
                            currentProgram
                          );
                        }}
                      />
                    ))
                  }
                </Box>
              </Collapse>

              {!config ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyItems: "center",
                    padding: 0.8,
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "#52433C",
                      fontSize: window.innerWidth > 400 ? "16px" : "12px",
                    }}
                  >
                    {getTimeFromDateIsoString(currentProgram.startTimestamp)}
                  </Typography>
                  {anyOutputOpened() ? (
                    <AccessAlarmsRoundedIcon
                      sx={{
                        paddingLeft: 1,
                        paddingRight: 1,
                        color: "#DD362E",
                      }}
                    />
                  ) : (
                    <AccessTimeRoundedIcon
                      sx={{
                        paddingLeft: 1,
                        paddingRight: 1,
                        color: "#52433C",
                      }}
                    />
                  )}
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "#52433C",
                      fontSize: window.innerWidth > 400 ? "16px" : "12px",
                    }}
                  >
                    {getTimeFromDateIsoString(currentProgram.endTimestamp)}
                  </Typography>

                  {activationDetail ? (
                    <KeyboardArrowUpIcon
                      sx={{ paddingLeft: 1 }}
                      size="large"
                      onClick={() => setActivationDetail(false)}
                    />
                  ) : (
                    <KeyboardArrowDownIcon
                      sx={{ paddingLeft: 1 }}
                      size="large"
                      onClick={() => setActivationDetail(true)}
                    />
                  )}
                </Box>
              ) : (
                <div></div>
              )}
              <Collapse
                sx={{ border: 0, padding: 0, borderTop: "1px solid #2A160B" }}
                in={activationDetail && !config}
                timeout="auto"
                unmountOnExit
              >
                {currentProgram.activations.map((activation, index) => {
                  return (
                    <Box sx={{ display: "flow" }}>
                      <TableRow
                        fullWidth
                        sx={{
                          backgroundColor: "transparent",
                          padding: 0,
                        }}
                      >
                        <TableCell
                          rowSpan={2}
                          sx={{
                            padding: 1,
                            paddingTop: 0,
                            border: 0,
                            alignItems: "start",
                            alignContent: "start",
                          }}
                        >
                          <Avatar
                            sx={{
                              border: "1px solid #52433C",
                              width: 24,
                              height: 24,
                              backgroundColor: "transparent",
                              variant: "body2",
                              top: -2,
                              fontSize: 12,
                              color: "#52433C",
                              padding: 0,
                            }}
                            //  alt={`Avatar n°${value + 1}`}
                            // src={`/static/images/avatar/${value + 1}.jpg`}
                          >
                            {index + 1}
                          </Avatar>
                        </TableCell>
                        <TableCell
                          colSpan={3}
                          sx={{
                            padding: 0,
                            paddingTop: 2,
                            border: 0,
                            alignItems: "end",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              width: "100%",
                              backgroundColor: "transparent",
                              alignItems: "center",
                              justifyContent: "flex-start",
                            }}
                          >
                            {outputs.map((output, index) => {
                              if (activation.outputs & (1 << index)) {
                                return (
                                  <Avatar
                                    sx={{
                                      backgroundColor: isActivationStarted(
                                        activation.offset,
                                        activation.unitsLimit
                                      )
                                        ? "#DD362E"
                                        : "#2A160B",
                                      width: 24,
                                      height: 24,
                                      color: "#F5ECE7",
                                      fontSize: 12,
                                      padding: 0,
                                      marginLeft: 0.4,
                                    }}
                                    //  alt={`Avatar n°${value + 1}`}
                                    // src={`/static/images/avatar/${value + 1}.jpg`}
                                  >
                                    {index + 1}
                                  </Avatar>
                                );
                              }
                            })}
                            {/*activation.outputs+ ' '+activation.outputs.toString(2).padEnd(dataOutputs.length, '0')+' '+ (activation.outputs & (1 << index))}*/}
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        fullWidth
                        sx={{
                          backgroundColor: "transparent",
                          padding: 0,
                        }}
                      >
                        <TableCell
                          sx={{
                            padding: 1,
                            paddingRight: 0,
                            border: 0,
                          }}
                        >
                          <Typography
                            variant="h2"
                            sx={{
                              textAlign: "end",
                              color: "#52433C",
                              fontSize:
                                window.innerWidth > 400 ? "16px" : "12px",
                            }}
                          >
                            {getTimeFromDateIsoString(
                              currentProgram.startTimestamp,
                              activation.offset
                            )}
                            {" - "}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: 1,
                            paddingLeft: 0.2,
                            border: 0,
                          }}
                        >
                          <Typography
                            variant="h2"
                            sx={{
                              fontSize:
                                window.innerWidth > 400 ? "16px" : "12px",
                              color: "#52433C",
                            }}
                          >
                            {getTimeFromDateIsoString(
                              currentProgram.startTimestamp,
                              activation.offset + activation.unitsLimit
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: 1,
                            paddingLeft: 0,
                            border: 0,
                          }}
                        >
                          <Typography
                            variant="h2"
                            sx={{
                              fontSize:
                                window.innerWidth > 400 ? "16px" : "12px",
                            }}
                          >
                            {activation.description}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </Box>
                  );
                })}
              </Collapse>
            </TableCell>
          </TableRow>
        </Table>
      </Box>
      <div ref={ref} />
    </ThemeProvider>
  );
};

export default DisplayProgram;
