import React, { useState } from "react";
import theme, {
  StyledToggleButtonGroup,
  StyledDayButton,
  boxShadow,
} from "./programmerTheme.js";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const DisplayDays = (props) => {
  const config = props.config;

  const daysLabel = ["L", "M", "X", "J", "V", "S", "D"];

  function getDaysFromBits() {
    const letters = ["D", "L", "M", "X", "J", "V", "S"];
    var parsed = [];
    letters.forEach((letter, index) => {
      if (props.program.days & (1 << (6 - index))) parsed.push(letter);
    });

    return parsed;
  }
  const [daysNumber, setDaysNumber] = useState(props.program.days)

  // devuelve el entero de los días seleccionados
  function getDaysOn(daysLabel) {
    const letters = ["D", "L", "M", "X", "J", "V", "S"];
    var zeros = Array(7).fill(0);

    daysLabel.map((letter) => {
      const index = letters.indexOf(letter);
      zeros[index] = index === -1 ? 0 : 1;
    });

    return parseInt(zeros.join(""), 2);
  }

  const handleChange = (event, newDaysLabel) => {
    const newDaysNumber = getDaysOn(newDaysLabel);
    setDaysNumber(newDaysNumber)
    props.changeProgramDays(newDaysNumber);
  };

  const handleAllChange = () => {
    const newDaysNumber = (daysNumber === 127) ? 0 : 127;
    setDaysNumber(newDaysNumber)
    props.changeProgramDays(newDaysNumber);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        display="grid"
        gridTemplateColumns="repeat(4, 1fr)"
        gap={0}
        sx={{ padding: 0, paddingTop: 1 }}
      >
        {config ? (
          <Box display={"flex"} gridColumn="span 4" sx={{}}>
            <Typography
              variant="subtitle2"
              sx={{
                border: 0,
                padding: 1,
                paddingBottom: 0,
                fontSize: window.innerWidth > 400 ? "16px" : "12px",
              }}
            >
              Días de la semana
            </Typography>
            <Button
              variant="text"
              sx={{ color: "#DD362E", fontSize: window.innerWidth > 400 ? "16px" : "12px",
              alignItems: "flex-start" }}
              onClick={handleAllChange}
            >
              {daysNumber === 127 ? "Ninguno" : "Todos"}
            </Button>
          </Box>
        ) : (
          <span />
        )}
       


        <Box gridColumn="span 4" sx={{ padding: 0 }}>
          <StyledToggleButtonGroup
            value={getDaysFromBits(props.program.days)}
            disabled={!config}
            sx={{ paddingLeft: 0.8 }}
            //   blurOnSelect={true}
            onChange={handleChange}
          >
            {daysLabel.map((d) => (
              <StyledDayButton
                disabled={!config}
                variant={"contained"}
                value={d}
                aria-label={d}
                sx={{ alignContent:'center', width: 32, height: 32, fontSize: window.innerWidth > 400 ? "16px" : "12px", }}
              >
                {d}
              </StyledDayButton>
            ))}
          </StyledToggleButtonGroup>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default DisplayDays;
